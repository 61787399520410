import TomSelect from "tom-select";

/*
To change a regular select element to an autocomplete select element, wrapt that select element with `<div class='autocomplete-select'></div>`
Additional properties for <select> element:
  data-autocomplete-create: true - if true, will determine if the component is in normal mode or create mode (note: tweak the form to ignore the error like the way we do in accounts/forms/auth.py)
  data-autocomplete-open-on-focus: will determine if we should open on focus

Additional properties for <option> element:
  data-image-src: url to image to display; if not provided, no image will be displayed

Additional Docs: https://tom-select.js.org/
*/

// function that generates html for the autocomplete dropdown items and selected item
function renderFnWithImage(data, escape) {
  let imageHTML = data.imageSrc
    ? `<img class="me-2" src="${data.imageSrc}"/>`
    : "";
  return `<div>${imageHTML} ${data.text}</div>`;
}

function basicRenderFn(item, escape) {
  const label =
    item.text ?? `${item.first_name} ${item.last_name} (${item.email})`;
  return `<div class="py-2 d-flex" notranslate>
  <div>${escape(label)}</div>
  </div>`;
}

// Autocomplete Selector
export default function autocompleteSelector(elList) {
  elList.forEach((el) => {
    // Selecting the first select element of the parent element containing `autocomplete-selector` class
    const selectElement = el.getElementsByTagName("select")[0];
    const apiEndpoint = el.dataset.apiEndpoint;

    const basicSettings = {
      sortField: {
        field: "text",
        direction: "asc",
      },
      // if data-autocomplete-create is specified, will determine if the the component is in normal mode or create mode
      create:
        (el.dataset.autocompleteCreate || "false").toLowerCase() === "true",

      // if data-open-on-focus is specified, will determine if we should open on focus; defaults to true
      openOnFocus:
        (el.dataset.autocompleteOpenOnFocus || "true").toLowerCase() === "true",

      // items in dropdown and selected item should both be rendered with the same function
      render: {
        option: renderFnWithImage,
        item: renderFnWithImage,
      },
    };

    const settingsWithApiEndpoint = {
      valueField: "id",
      searchField: ["first_name", "last_name", "email"],
      loadThrottle: 500, // slightly increase default throttle value
      preload: true, //setting this to true will call load function on page load
      closeAfterSelect: true,
      maxOptions: 10,

      // fetch remote data only ONCE, when the select is first created
      load: function (query, callback) {
        let self = this;
        if (self.loading > 1) {
          callback();
          return;
        }
        fetch(apiEndpoint)
          .then((response) => response.json())
          .then((data) => {
            callback(data);
            self.settings.load = null;
          })
          .catch(() => {
            callback();
          });
      },
      // custom rendering functions for options and items
      render: {
        option: basicRenderFn,
        item: basicRenderFn,
      },
    };

    const tomSettings = apiEndpoint ? settingsWithApiEndpoint : basicSettings;
    // Creating a new instance of the TomSelect component if not already initialized on this element
    if (selectElement && !Object.hasOwn(selectElement, "tomSelect")) {
      selectElement.tomSelect = new TomSelect(selectElement, tomSettings);
    }
  });
}
