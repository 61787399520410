// require('jquery');
// require('popper.js');
// require('bootstrap');
var tooltipTemplate = require('./templates/tooltip.ejs');

class Tooltip {
    constructor(el) {
        this.el = el;
        this.criteriaText = el.dataset['criteriaText'];
        this.tooltip = el.dataset['tooltip'];
        this.assessmentGuidance = el.dataset['assessmentGuidance'];
        this.referenceDocuments = JSON.parse(el.dataset['referenceDocuments']);
        this.recommendedDocuments = JSON.parse(el.dataset['recommendedDocuments']);
        this.scoringMatrix = JSON.parse(el.dataset['scoringMatrix']);

        // add super/sub-scripting to scoring matrix
        this.scoringMatrix.forEach((matrixElement) => { matrixElement['value'] = text_super_sub(matrixElement['value']); });

        this.module = el.dataset['module'];
        this.criteriaId = el.dataset['criteriaId'];
        var tech_manual_url = `/technical_manual/${this.module}#criteria-${this.criteriaId}`;

        // initialize popover and bind for click event
        $(el).popover({
            placement: 'bottom',
            content: '<div class="gg-popover-loading"><i class="fa fa-loading fa-spin mr-1"></i> Loading...</div>',
            template: '<div class="popover gg-popover" notranslate role="tooltip"><div class="arrow"></div><div class="gg-popover-header d-md-flex align-items-center p-3 d-none"><h6 class="m-0 mr-auto">Additional Information</h6><a href="' + tech_manual_url + '" target="_blank" class="btn btn-primary mr-1 btn-sm"><i class="fa fa-new-window"></i> Technical Reference Manual</a> <i class="fa fa-close"></i></div><div class="popover-body" notranslate></div></div>',
            html: true,
            // display: 'static',
            trigger: 'click'
        });

        // track if popover is active or not; used when tracking clicks outside of popover
        // modal will only be re-rendered if active is set to true
        // button clicks outside of the modal will hide the modal when active is set to true
        this.active = false;
        $(el).on('shown.bs.popover', (e) => { this.active = true; });
        $(el).on('hidden.bs.popover', (e) => { this.active = false; });

        // track when clicks are registered on page and determine if they occured outside of an active popover
        $('body').on('click', (e) => {
            if(this.active && ($(e.target).parents('.activity-log-content').length === 0)) {
                $(el).popover('hide');
            }
        });

        // render the initial content on load
        $(el).on('shown.bs.popover', (e) => { this.render(); });

        // prevent button from triggering link
        $(el).on('click', (e) => { e.preventDefault(); });
    }
    render() {
        // hide any existing tooltips prior to rendering the buttons; prevents 'ghost' tooltips
        //$(this.el).tooltip('hide');

        // get the popover body element we will be updating
        var el = $(this.el).data('bs.popover').tip.querySelector('.popover-body');

        var criteriaInputName = 'criteria_' + this.criteriaId;

        el.innerHTML = tooltipTemplate({
            criteriaText: this.criteriaText,
            tooltip: this.tooltip,
            assessmentGuidance: this.assessmentGuidance,
            referenceDocuments: this.referenceDocuments,
            recommendedDocuments: this.recommendedDocuments,
            scoringMatrix: this.scoringMatrix,
            splitValueStringToList: splitValueStringToList,
            responseValue: this.getValue(criteriaInputName),
            responseLabel: this.getLabel(criteriaInputName),
        });
    }

    getValue(criteriaInputName) {
        /* returns the value for the currently selected item */
        var inputEls = document.querySelectorAll('[name="' + criteriaInputName + '"]');
        let checkedEl = _.find(inputEls, (el) => el.checked);

        if(_.find(inputEls, (el) => el.type == 'checkbox')) {
            // if input is a checkbox, return checked value if checked or the corresponding hidden input's value if not
            // hidden input's value is the correct value for unchecked checkbox inputs
            return checkedEl ? checkedEl.value : _.find(inputEls, (el) => el.type == 'hidden').value;
        } else if(_.find(inputEls, (el) => el.type == 'radio')) {
            // if input is radio, return the checked radio's value or undefined
            return checkedEl ? checkedEl.value : undefined;
        } else if(inputEls.length == 1) {
            // if traditional one-input response, return that criteria's value
            return inputEls[0].value;
        } else {
            return undefined;
        }
    }
    getLabel(criteriaInputName) {
        /* returns the label for the currently selected item */
        var inputEls = document.querySelectorAll('[name="' + criteriaInputName + '"]');
        let checkedEl = _.find(inputEls, (el) => el.checked);

        if(_.find(inputEls, (el) => el.type == 'radio')) {
            // if input is radio, return the checked radio's label or undefined
            return checkedEl ? checkedEl.dataset['label'] : undefined;
        } else {
            return this.getValue(criteriaInputName);
        }
    }
}

function text_super_sub(text) {
    // replace --word-- with <sup>word</sup> and __word__ with <sub>word</sub>
    return text.replace(/__(\w+)__/g, '<sub>$1</sub>').replace(/--(\w+)--/g, '<sup>$1</sup>')
}
function splitValueStringToList(value_string) {
    /* converts a string of potentailly grouped values into a list of values, eg:
     * 'apple' => ['apple']
     * 'apple,orange' => ['apple', 'orange']
     * '5-8' => ['5','6','7','8']
     * */
    let numRangeMatch = /^(\d+)-(\d+)$/.exec(value_string)
    if(numRangeMatch) {
        let minValue = parseInt(numRangeMatch[1])
        let maxValue = parseInt(numRangeMatch[2])
        return Array.from({length:maxValue + 1 - minValue}, (v, k) => (minValue + k).toString());
    } else {
        return value_string.split(',')
    }
}
export default Tooltip;
