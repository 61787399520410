module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="tooltip-container">\n    <div class="trumbowyg-content">\n        <div class="py-2 px-3 gg-tooltip-criterion-text">\n            <h6 class="text-dark">Criterion</h6>\n            <div class="text-dark">' +
((__t = ( criteriaText )) == null ? '' : __t) +
'</div>\n        </div>\n\n        <div class="py-2 px-3">\n            ';
 if(tooltip) { ;
__p += '\n                <h6 class="text-dark">Tooltip</h6>\n                <div class="mb-3">' +
((__t = ( tooltip )) == null ? '' : __t) +
'</div>\n            ';
 } ;
__p += '\n\n            ';
 if(assessmentGuidance) { ;
__p += '\n                <h6 class="text-dark">Assessment Guidance</h6>\n                <div class="mb-3">' +
((__t = ( assessmentGuidance )) == null ? '' : __t) +
'</div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n\n    ';
 if(referenceDocuments.length) { ;
__p += '\n        <div class="py-2 px-3">\n            <h6 class="text-dark">References</h6>\n            <ul>\n                ';
 for(var i = 0; i < referenceDocuments.length; i++) { ;
__p += '\n                    <li>\n                        ';
 if(referenceDocuments[i].url) { ;
__p += '\n                            <a href="' +
((__t = ( referenceDocuments[i].url )) == null ? '' : __t) +
'" target="_blank"><i>' +
((__t = ( referenceDocuments[i].name )) == null ? '' : __t) +
'</i></a>\n                        ';
 } else { ;
__p += '\n                            <i>' +
((__t = ( referenceDocuments[i].name )) == null ? '' : __t) +
'</i>\n                        ';
 } ;
__p += '\n                    </li>\n                ';
 } ;
__p += '\n            </ul>\n        </div>\n    ';
 } ;
__p += '\n\n    ';
 if(recommendedDocuments.length) { ;
__p += '\n        <div class="py-2 px-3">\n            <h6 class="text-dark">Recommended Documentation</h6>\n            <ul>\n                ';
 for(var i = 0; i < recommendedDocuments.length; i++) { ;
__p += '\n                    <li>\n                        ';
 if(recommendedDocuments[i].url) { ;
__p += '\n                            <a href="' +
((__t = ( recommendedDocuments[i].url )) == null ? '' : __t) +
'" target="_blank"><i>' +
((__t = ( recommendedDocuments[i].name )) == null ? '' : __t) +
'</i></a>\n                        ';
 } else { ;
__p += '\n                            <i>' +
((__t = ( recommendedDocuments[i].name )) == null ? '' : __t) +
'</i>\n                        ';
 } ;
__p += '\n                    </li>\n                ';
 } ;
__p += '\n            </ul>\n        </div>\n    ';
 } ;
__p += '\n\n\n\n    ';
 if(scoringMatrix.length) { ;
__p += '\n        <div class="py-2 px-3">\n            <h6 class="text-dark">Scoring Matrix</h6>\n            <table class="table">\n                <thead class="thead-light">\n                    <tr>\n                        <th>Response</th>\n                        <th>Score</th>\n                        <th>Max Points</th>\n                    </tr>\n                </thead>\n                <tbody>\n                    ';
 for(var i = 0; i < scoringMatrix.length; i++) { ;
__p += '\n                        <tr ';
 if(splitValueStringToList(scoringMatrix[i].value).indexOf(responseLabel) != -1) { ;
__p += 'style="background-color: #fff9e7;"';
 } ;
__p += '>\n                            <td>' +
((__t = ( scoringMatrix[i].value )) == null ? '' : __t) +
'</td>\n                            <td>' +
((__t = ( scoringMatrix[i].achieved )) == null ? '' : __t) +
'</td>\n                            <td>' +
((__t = ( scoringMatrix[i].total )) == null ? '' : __t) +
'</td>\n                        </tr>\n                    ';
 } ;
__p += '\n                </tbody>\n            </table>\n        </div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
}