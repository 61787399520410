// if a user clicks on any part of a clickable row, trigger the row's link
function ClickableRow(elList) {
    elList.forEach((el) => {
        el.addEventListener('click', (e) => {
            // do not trigger row's link if the clicked item is an input/button/textarea/link
            if (['input', 'button', 'textarea', 'a'].indexOf(e.target.tagName.toLowerCase()) > -1) {
                return;
            } else if(!e.currentTarget) { 
                // additional validation for an error that seems to be happening on rare occasions
                return; 
            }
            var actionEl = e.currentTarget.querySelector('a') || e.currentTarget.querySelector('button') || e.currentTarget.querySelector('input[type=radio]');
            if (actionEl) {
                actionEl.click();
            }
        });
    });
}
export default ClickableRow;
